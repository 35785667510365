import sunny from "@/assets/weather-icons/1.png";
import mostly_sunny from "@/assets/weather-icons/2.png";
import cloudy from "@/assets/weather-icons/3.png";
import heavily_cloudy from "@/assets/weather-icons/4.png";
import thunderstorm_heat from "@/assets/weather-icons/5.png";
import heavy_rain from "@/assets/weather-icons/6.png";
import snowfall from "@/assets/weather-icons/7.png";
import fog from "@/assets/weather-icons/8.png";
import sleet from "@/assets/weather-icons/9.png";
import sleet2 from "@/assets/weather-icons/10.png";
import light_rain from "@/assets/weather-icons/11.png";
import snow_shower from "@/assets/weather-icons/12.png";
import thunderstorm from "@/assets/weather-icons/13.png";
import low_stratus from "@/assets/weather-icons/14.png";
import sleet_shower from "@/assets/weather-icons/15.png";

export const assets = {
  1: sunny,
  2: mostly_sunny,
  3: cloudy,
  4: heavily_cloudy,
  5: thunderstorm_heat,
  6: heavy_rain,
  7: snowfall,
  8: fog,
  9: sleet,
  10: sleet2,
  11: light_rain,
  12: snow_shower,
  13: thunderstorm,
  14: low_stratus,
  15: sleet_shower,
};
