// imports react and next
import { Image as DatoImage } from "react-datocms";
import Link from "next/link";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dynamic from "next/dynamic";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";

// imports components
const Lightbox = dynamic(() => import("@/components/Lightbox"));
const Button = dynamic(() => import("@/common/UI/Button"));

// imports utilities
import { isEmpty } from "@/utils/functions";

// imports assets
import { assets } from "helpers/assets";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const StickyScrollBar = () => {
  const [open, setOpen] = useState(false);
  const [lightboxData, setLightboxData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [slides, setSlides] = useState([]);
  const [weatherData, setWeatherData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { siteConfig, buttonsAndLabelsPage } = useSelector((state) => {
    return {
      siteConfig: state?.labelsConfig?.labelsAndConfigData?.siteConfig,
      buttonsAndLabelsPage: state?.labelsConfig?.labelsAndConfigData?.buttonsAndLabelsPage,
    };
  });

  const apiFetcher = useCallback(async () => {
    const res = await fetch("/api/weather", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
    });
    return res.json();
  }, []);

  useEffect(() => {
    const webcamImages = [];
    if (!isEmpty(siteConfig)) {
      new Array(4)?.fill(0).forEach((i, index) => {
        webcamImages.push({
          src: siteConfig[`webcam${index + 1}`],
          alt: `webcam${i}`,
        });
      });
      setSlides(webcamImages);
    }
  }, [siteConfig]);

  const fetchWeatherData = useCallback(async () => {
    const weekForecastData = await apiFetcher();

    const weekdays = [
      buttonsAndLabelsPage?.monday,
      buttonsAndLabelsPage?.tuesday,
      buttonsAndLabelsPage?.wednesday,
      buttonsAndLabelsPage?.thursday,
      buttonsAndLabelsPage?.friday,
      buttonsAndLabelsPage?.saturday,
      buttonsAndLabelsPage?.sunday,
    ];

    const updatedData = weekForecastData?.prognose?.tnr00001?.map((data, index) => {
      const currentDay = weekdays[index % 7]?.slice(0, 3) || "";

      return {
        icon: assets?.[data?.D?.SY],
        temperature: data?.D?.TT,
        currentDay,
      };
    });

    const filteredData = updatedData.slice(0, 7);

    setWeatherData(filteredData);
    setIsLoading(false);
  }, [buttonsAndLabelsPage, apiFetcher]);

  const handleModal = useCallback(
    (type = "webcam") => {
      if (type === "map") {
        const mapConfig = {
          slides: [
            {
              src: siteConfig?.campingMap?.url,
              alt: siteConfig?.campingMap?.alt,
            },
          ],
          carousel: {
            finite: true,
          },
          plugins: [Zoom, Fullscreen],
          animation: { zoom: 200 },
          zoom: {
            maxZoomPixelRatio: 1.1,
            zoomInMultiplier: 5,
          },
        };
        setLightboxData(mapConfig);
      } else {
        const webcamConfig = {
          slides: [...slides],
          carousel: { preload: 2 },
          plugins: [Thumbnails],
          thumbnails: {
            position: "bottom",
            width: 120,
            height: 80,
            border: 1,
            borderRadius: 4,
            padding: 4,
            gap: 16,
          },
        };
        setLightboxData(webcamConfig);
      }
      setOpen(true);
    },
    [siteConfig, slides],
  );

  const handleMeteo = useCallback(() => {
    fetchWeatherData();
    setShowModal(true);
    setIsLoading(true);
  }, [fetchWeatherData]);

  return (
    <>
      <div className="lg:fixed xxs:absolute sm:top-[235px] xxs:top-[223px] left-0 z-30 text-center sm:max-h-[280px] xxs:max-h-[184px] h-full sm:max-w-[81px] xxs:max-w-[46px] w-full text-white font-bold text-sm leading-[18px] tracking-[0.2px]">
        <Button onClick={() => handleModal("map")} className="w-full">
          <div className="py-2 bg-light-blue hover:underline hover:cursor-pointer">
            <div className="text-white">
              <iconify-icon
                className="iconify"
                icon="ic:outline-map"
                width="30"
                height="30"
              ></iconify-icon>
            </div>
            <span className="xxs:hidden sm:block font-futuraBold">{buttonsAndLabelsPage?.map}</span>
          </div>
        </Button>
        <Button onClick={() => handleModal()} className="w-full">
          <div className="py-2 bg-dull-yellow hover:underline hover:cursor-pointer">
            <div className="">
              <iconify-icon icon="uil:webcam" width="30" height="30"></iconify-icon>
            </div>
            <span className="xxs:hidden sm:block font-futuraBold">
              {buttonsAndLabelsPage?.webcam}
            </span>
          </div>
        </Button>
        <Link href={"/tour"}>
          <div className="py-2 bg-bright-yellow hover:underline hover:cursor-pointer">
            <div className="">
              <iconify-icon icon="tabler:360-view" width="30" height="30"></iconify-icon>
            </div>
            <span className="xxs:hidden sm:block font-futuraBold">
              {buttonsAndLabelsPage?.tour}
            </span>
          </div>
        </Link>
        <Button onClick={handleMeteo} className="w-full">
          <div className="py-2 bg-danger hover:underline hover:cursor-pointer">
            <div className="">
              <iconify-icon icon="ph:cloud-sun-bold" width="30" height="30"></iconify-icon>
            </div>
            <span className="xxs:hidden sm:block font-futuraBold">
              {buttonsAndLabelsPage?.meteo}
            </span>
          </div>
        </Button>
      </div>
      {showModal && (
        <>
          <div className="modal">
            <div className="text-[#333333] bg-white modal-content m-auto lg:w-[80%] sm:w-[90%] xxs:w-[90%] max-h-[80%] relative text-center flex justify-center">
              <div className="flex flex-col gap-y-8 overflow-auto lg:p-[72px] sm:p-10 xxs:p-5">
                <div
                  onClick={() => setShowModal(false)}
                  className="close absolute -top-[10px] -right-[10px] text-[#f1f1f1] text-xl font-bold bg-danger"
                >
                  <iconify-icon icon="system-uicons:cross" width="25" height="25"></iconify-icon>
                </div>
                <h5 className="text-xl leading-7 tracking-[0.2px] font-bold font-futuraBold">
                  {buttonsAndLabelsPage?.weatherTitle}
                </h5>
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <div
                    className={`flex flex-wrap justify-center md:gap-x-28 lg:gap-x-4 xxl:gap-x-16 xxxl:gap-x-24 gap-y-8 `}
                  >
                    {weatherData?.map((data, index) => (
                      <div
                        key={index}
                        className={`flex justify-between items-center w-2/3 md:w-[40%]   ${
                          index >= 3 ? "lg:w-[23%] xxl:w-[19%]" : "lg:w-[25%] xxl:w-[22%]"
                        } `}
                      >
                        <div className="w-12">
                          <p className="font-futuraBold">{data?.currentDay}</p>
                        </div>
                        <div className="bg-[#E5F0FF] rounded-full w-[50px] h-[50px] flex items-center justify-center">
                          <DatoImage
                            data={{
                              src: data?.icon?.src,
                              alt: "weather",
                              title: data?.title,
                              width: 40,
                              height: 40,
                            }}
                          />
                        </div>
                        <div className="w-16 ">
                          <p className="caption font-futuraBold">
                            {`${data?.temperature}`}
                            &deg; C
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div className="flex items-center justify-center">
                  <Button
                    className="w-auto max-h-[52px] btn border-danger"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="font-futuraBold text-danger">
                      {buttonsAndLabelsPage?.close}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {open && !isEmpty(lightboxData) && (
        <Lightbox
          open={open}
          close={() => {
            setOpen(false);
            setLightboxData({});
          }}
          slides={lightboxData?.slides || []}
          carousel={lightboxData?.carousel || {}}
          plugins={lightboxData?.plugins || {}}
          animation={lightboxData?.animation || {}}
          zoom={lightboxData?.zoom || {}}
          thumbnails={lightboxData?.thumbnails || {}}
        />
      )}
    </>
  );
};

export default StickyScrollBar;
